import React from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "components/Layout";
import "./Links.scss";

export default function Links({ location }) {
  const pathName = location.pathname.split("/")[1];

  return (
    <Layout pathName={pathName}>
      <Container fluid>
        <div className="toms-pool">
          <StaticImage
            src="../../content/assets/images/SPA Who needs gas heater.png"
            width="250"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="SPA Who needs gas heater? &#x1F60A;"
            placeholder="blurred"
            layout="constrained"
          />
          <p>Who needs gas heater for SPA? =-)</p>
        </div>
        <div className="intro">
          <h3>
            Roseville Pool Service <small>- Links</small>
          </h3>
          <hr />
          <section>
            <div>
              <ul className="link-list">
                <li>
                  <a href="https://www.pentairpool.com/en/support/rebate%20center" target="new">
                    <b>Pentair pool equipment product rebates</b>
                  </a>
                </li>
                <li>
                  <a href="http://www.hayward-pool.com/shop/en/pools/Rebates" target="new">
                    <b>Hayward pool equipment product rebates</b>
                  </a>
                </li>
                <li>
                  <a href="https://www.polarispool.com/en/rebates" target="new">
                    <b>Polaris pool cleaners rebates</b>
                  </a>
                </li>
                <li>
                  <a href="https://www.zodiacpoolsystems.com/en/rebates" target="new">
                    <b>Zodiac pool cleaners rebates</b>
                  </a>
                </li>
                <li>
                  <a href="http://www.askalanaquestion.com/" target="new">
                    <b>
                      AskAlanAQuestion.Com . . . help with pool and spa water problems and
                      information!!!
                    </b>
                  </a>
                  : Excellent information, questions and answers to many problems with swimming
                  pools - algae, staining, .....
                </li>
                <li>
                  <a href="http://www.ipssa.com/" target="new">
                    <b>IPSSA</b>
                  </a>
                  : web site of the Independent Pool and Spa Service Association, Inc.
                </li>
                <li>
                  <b>
                    <a target="new" href="http://www.rocklintoday.com/">
                      Rocklin &amp; Roseville Today
                    </a>
                  </b>
                  :&nbsp; Offers community news, a business directory and yellow pages.
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Container>
    </Layout>
  );
}

Links.propTypes = {
  location: PropTypes.object,
};

Links.defaultProps = {
  location: null,
};
